


























import { DataTableColDef, DataTableDef, DataTableRowDef } from '@/app_code/DataTable'
import DataTable from '@/components/UI/Elements/DataTable/DataTable.vue'
import { CountryModel } from 'truemarket-modules/src/models/api/admin'
import { Services } from 'truemarket-modules/src/services'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    DataTable
  }
})
export default class Index extends Vue {
  private isAddingCountry = false

  private addCountryName = ''

  private countries: CountryModel[] = []

  private dtDef = new DataTableDef(new DataTableRowDef(row => row.CountryId, [
    new DataTableColDef('Country', row => row.Name),
    new DataTableColDef('Id', row => row.CountryId)
  ]))

  loadCountries () {
    Services.API.Admin.Countries.GetCountries().then((countries) => {
      this.countries = countries
    })
  }

  mounted () {
    this.loadCountries()
  }

  addCountry () {
    Services.API.Admin.Countries.CreateCountry({ Name: this.addCountryName }).then(() => {
      this.loadCountries()

      this.isAddingCountry = false
    })
  }

  editCountry (countryId: string) {
    this.$router.push(`/dashboard/admin/countries/${countryId}`)
  }
}
